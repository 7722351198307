body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-size: cover;
}

#cigo-auth-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.app-container h1 {
  color: #6b6b6b;
  font-size: 22px;
  margin-top: 5px;
}

.app-container h2 {
  color: #6b6b6b;
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
}

.app-container p {
  color: #9A9A9A;
  margin-bottom: 18px;
  font-weight: 500;
}

.app-container .help-link {
  text-decoration: none;
  color: #8b8b8b;
  font-size: 12px;
}

.app-container .help-link a {
  text-decoration: none;
  color: #8b8b8b;
}

.app-container .help-link button {
  font-size: 12px;
}

.no-cursor {
  caret-color: transparent;
}

.card-header,
.card-footer {
  background: unset;
  border: unset;
}

.footer-links-container a {
  text-decoration: none;
  font-size: 11px;
  font-weight: 500;
  color: #8B8B8B;
  padding: 2px 7px;
  text-align: center;
}

.copyright-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 10px;
  color: #8B8B8B;
  margin: 10px auto 5px auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn svg {
  margin-right: 8px;
}

.remember-device-checkbox{
  transform: scale(1.5);
}

.card {
  max-width: 400px;
  margin: 0 auto;
}

.password-strength-list li svg {
  margin-right: 10px;
}

.list-group-item {
  font-style: italic;
}

.languageSwitcherContainer {
  position: relative;
  width: 80px;
}

.languageSwitcherContainer svg {
  color: #1F94FC;
  position: absolute;
  left: 3px;
  top: 5px;
}

.languageSwitcherContainer select {
  cursor: pointer;
  padding-left: 1.5rem;
  font-size: 0.75rem;
  border: none;
}

.languageSwitcherContainer select:focus {
  outline: none;
  box-shadow: none;
}

/* fix implemented for safari browsers CT-4201 */
.fixed-width-submit-button {
  min-width: 90px;
  max-width: 100px;
}